import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Sidebar from '../../components/articles/sidebar'
import SocialSharing from '../../components/sharing'
import { Link, graphql } from "gatsby"
import { data } from 'autoprefixer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import DotTypeBlog from '../../images/svg/dot-type-blog.svg';


const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} className="underline">
          {children}
        </a>
      )
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2>{children}</h2>
    },
  },
}

const ArticlePost = ({ data, location }) => {
    console.log("data template : ", data);
    console.log("location : ", location);
    return (
        <>

        <main>
        <div className="lg:overflow-hidden relative">
          <div className="mx-auto">
            <div className="">
              <div className="mx-auto sm:max-w-2xl md:flex md:items-center md:px-0 md:text-left">
                <div className="md:py-24 md:pl-28	md:pr-28">
                  <div className='text-sm text-orange-200 flex text-center justify-center	mt-8 mb-5'>
                    <img className="mr-2" src={DotTypeBlog} alt="" />
                    {data.gogobackend.article.category_en}
                  </div>
                  <h1 className="text-comorant text-center lg:text-7xl text-6xl tracking-tight text-onyx mb-8 pt-44 md:pt-0 md:px-0 font-semibold uppercase">
                    {data.gogobackend.article.title_en}
                  </h1>
                  <p className="text-onyx text-sm lg:px-0 px-14 uppercase text-center font-bold">
                  BY {data.gogobackend.article.author_en}
                  </p>
                  <p className="text-onyx text-sm lg:px-0 px-14 uppercase text-center font-bold">
                  AUGUST 11, 2022
                  </p>
                  <div className=''>
                    <SocialSharing url={location.href} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='px-5'>
            <div className=''>
              <div className='overflow-hidden	rounded-3xl mb-16' style={{  
                    height: '700px',
                  }}>
                    <img 
                        src={`https://api.clubmella.com/storage/images_disk/${data.gogobackend.article.image}`}
                        alt="Article photo"
                        style={{  
                          width: '100%',
                        }}
                    />
                </div>
                <div className='max-w-3xl	mx-auto text-article leading-normal text-skin-base text-lg text-onyx'>
                    <div dangerouslySetInnerHTML={{__html: data.gogobackend.article.content_en}} />
                </div>
            </div>
            <div className='pl-6'>
            
          </div>
        </div>


      </main>
    </>
        
    )
}

export const queryPrimary = graphql`

query MyQuery($slug: String) {
  gogobackend {
    article(
        slug: $slug
        ) {
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          image
      }
    }
  }
`

export default ArticlePost

